<template>
  <div>
    <v-col cols="3" class="ma-0 pa-0">
      <v-select
        :items="items"
        item-text="attr"
        item-value="value"
        v-model="isFullPage"
        label="Page"
        solo
        hide-details
      ></v-select>
    </v-col>
    <div class="temp_ mt-3">
      <!-- PAGE 1 -->
      <div v-if="page == 1 || isFullPage">
        <div class="identitiy">
          <section class="pa-2 primary-color mb-3">
            <h3 class="white--text"><b>IDENTITAS</b></h3>
          </section>

          <table class="table-identity" style="width: 100%">
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Nomor Tes TELADAN</v-col>
                  <v-col cols="9"
                    >: {{ person.no_tf ? person.no_tf : "-" }}</v-col
                  >
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Nama</v-col>
                  <v-col cols="9">: {{ person.nama_lengkap }}</v-col>
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Asal Universitas</v-col>
                  <v-col cols="9"
                    >:
                    {{ person.universitas ? person.universitas : "-" }}</v-col
                  >
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Tanggal Lahir</v-col>
                  <v-col cols="9"
                    >:
                    {{
                      person.tgl_lahir
                        ? $date(person.tgl_lahir).format("DD MMMM YYYY")
                        : "-"
                    }}</v-col
                  >
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Jenis Kelamin</v-col>
                  <v-col cols="9"
                    >:
                    {{
                      person.jenis_kelamin ? person.jenis_kelamin : "-"
                    }}</v-col
                  >
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Pendidikan Terakhir</v-col>
                  <v-col cols="9">: SMA/SMK/Sederajat</v-col>
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Tujuan Pemeriksaan</v-col>
                  <v-col cols="9"
                    >:
                    {{
                      $route.params.periode == "baseline"
                        ? `Seleksi Beasiswa TELADAN ${
                            person.tahun_tf ? person.tahun_tf : ""
                          }`
                        : `Asesmen Beasiswa TELADAN ${
                            person.tahun_tf ? person.tahun_tf : ""
                          }`
                    }}</v-col
                  >
                </v-row>
              </td>
            </tr>
            <tr class="table-identity">
              <td class="pa-3">
                <v-row>
                  <v-col cols="3">Periode Pemeriksaan</v-col>
                  <v-col cols="9">: {{ periode_pemeriksaan.periode }}</v-col>
                </v-row>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- VALUES & COMPETENCY -->
      <!-- PAGE 2 -->
      <div v-if="page == 2 || isFullPage">
        <header class="d-flex justify-end mb-3 mt-5">
          <b
            ><u>Nama : {{ person.nama_lengkap }}</u></b
          >
        </header>
        <section class="ml-7 ma-2"><b>A. VALUE</b></section>
        <table
          style="border: 1px solid black; border-collapse: collapse"
          v-if="dataScore"
        >
          <tr class="primary-color white--text text-center">
            <td class="border-around" style="width: 20%"><b>VALUE</b></td>
            <td class="border-around" style="width: 56%"><b>DEFINITION</b></td>
            <td class="border-around" style="width: 7%"><b>REQ</b></td>
            <td class="border-around" style="width: 10%"><b>ACTUAL</b></td>
            <td class="border-around" style="width: 7%"><b>GAP</b></td>
          </tr>
          <tr v-for="(item, idx) in values" :key="idx">
            <td
              :class="
                (idx + 1) % 2 == 0
                  ? 'border-around text-center strap text-capitalize'
                  : 'border-around text-center text-capitalize'
              "
            >
              <b
                ><p class="ma-0">{{ item.name.replace(/_/g, " ") }}</p></b
              >
            </td>
            <td
              :class="
                (idx + 1) % 2 == 0
                  ? 'border-around text-justify strap'
                  : 'border-around text-justify'
              "
            >
              <i>{{ item.behavior }}</i>
            </td>
            <td class="border-around text-center">
              <!-- req -->
              <b>{{ item.standar }}</b>
            </td>
            <td class="border-around text-center">
              <!-- actual -->
              <b>{{ item.score }}</b>
            </td>
            <td
              :class="
                item.score - item.standar < 0
                  ? 'border-around text-center val-min'
                  : item.score - item.standar === 0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <!-- gap -->
              <b>{{ item.score - item.standar }}</b>
            </td>
          </tr>
        </table>
        <section class="ml-7 mt-7 ma-2"><b>B. COMPETENCY</b></section>
        <table style="border: 1px solid black; border-collapse: collapse">
          <tr class="primary-color white--text text-center">
            <td style="width: 20%" class="border-around text-center">
              <b>COMPETENCY</b>
            </td>
            <td style="width: 36%" class="border-around text-center">
              <b>KEY BEHAVIOR</b>
            </td>
            <td style="width: 20%" class="border-around text-center">
              <b>SUB COMPETENCY</b>
            </td>
            <td style="width: 7%" class="border-around text-center">
              <b>REQ</b>
            </td>
            <td style="width: 10%" class="border-around text-center">
              <b>ACTUAL</b>
            </td>
            <td style="width: 7%" class="border-around text-center">
              <b>GAP</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center text-capitalize">
              <b>{{ competenciesRight[0].name.replace(/_/g, " ") }}</b>
            </td>
            <td class="border-around text-justify px-2">
              <i>{{ competenciesRight[0].behavior }}</i>
            </td>
            <td class="border-around text-center"><b>&nbsp;</b></td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[0].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[0].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[0].score - competenciesRight[0].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[0].score -
                      competenciesRight[0].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[0].score - competenciesRight[0].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap" rowspan="3">
              <b>Leadership</b>
            </td>
            <td class="border-around text-justify px-2 strap" rowspan="3">
              <i>{{ competenciesRight[1].behavior }}</i>
            </td>
            <td class="border-around text-center strap text-capitalize">
              <i> {{ competenciesRight[1].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[1].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[1].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[1].score - competenciesRight[1].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[1].score -
                      competenciesRight[1].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[1].score - competenciesRight[1].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap text-capitalize">
              <i>{{ competenciesRight[2].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[2].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[2].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[2].score - competenciesRight[2].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[2].score -
                      competenciesRight[2].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[2].score - competenciesRight[2].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap text-capitalize">
              <i>{{ competenciesRight[3].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[3].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[3].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[3].score - competenciesRight[3].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[3].score -
                      competenciesRight[3].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[3].score - competenciesRight[3].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center" rowspan="4">
              <b>Execution</b>
            </td>
            <td class="border-around text-justify px-2" rowspan="4">
              <i>{{ competenciesRight[4].behavior }}</i>
            </td>
            <td class="border-around text-center text-capitalize">
              <i>{{ competenciesRight[4].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[4].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[4].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[4].score - competenciesRight[4].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[4].score -
                      competenciesRight[4].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[4].score - competenciesRight[4].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center text-capitalize">
              <i>{{ competenciesRight[5].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[5].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[5].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[5].score - competenciesRight[5].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[5].score -
                      competenciesRight[5].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[5].score - competenciesRight[5].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center text-capitalize">
              <i>{{ competenciesRight[6].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[6].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[6].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[6].score - competenciesRight[6].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[6].score -
                      competenciesRight[6].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[6].score - competenciesRight[6].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center text-capitalize">
              <i>{{ competenciesRight[7].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[7].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[7].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[7].score - competenciesRight[7].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[7].score -
                      competenciesRight[7].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[7].score - competenciesRight[7].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap" rowspan="3">
              <b>Collaboration</b>
            </td>
            <td class="border-around text-justify px-2 strap" rowspan="3">
              <i>{{ competenciesRight[8].behavior }}</i>
            </td>
            <td class="border-around text-center strap text-capitalize">
              <i>{{ competenciesRight[8].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[8].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesRight[8].score }}</b>
            </td>
            <td
              :class="
                competenciesRight[8].score - competenciesRight[8].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesRight[8].score -
                      competenciesRight[8].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesRight[8].score - competenciesRight[8].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap text-capitalize">
              <i>{{ competenciesLeft[0].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[0].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[0].score }}</b>
            </td>
            <td
              :class="
                competenciesLeft[0].score - competenciesLeft[0].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesLeft[0].score - competenciesLeft[0].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesLeft[0].score - competenciesLeft[0].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap text-capitalize">
              <i>{{ competenciesLeft[1].name.replace(/_/g, " ") }}</i>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[1].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[1].score }}</b>
            </td>
            <td
              :class="
                competenciesLeft[1].score - competenciesLeft[1].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesLeft[1].score - competenciesLeft[1].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesLeft[1].score - competenciesLeft[1].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center text-capitalize">
              <b>{{ competenciesLeft[2].name.replace(/_/g, " ") }}</b>
            </td>
            <td class="border-around text-justify px-2">
              <i>{{ competenciesLeft[2].behavior }}</i>
            </td>
            <td class="border-around text-center"><b>&nbsp;</b></td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[2].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[2].score }}</b>
            </td>
            <td
              :class="
                competenciesLeft[2].score - competenciesLeft[2].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesLeft[2].score - competenciesLeft[2].standar ===
                    0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesLeft[2].score - competenciesLeft[2].standar
              }}</b>
            </td>
          </tr>
          <tr>
            <td class="border-around text-center strap text-capitalize">
              <b>{{ competenciesLeft[3].name.replace(/_/g, " ") }}</b>
            </td>
            <td class="border-around text-justify px-2 strap">
              <i>{{ competenciesLeft[3].behavior }}</i>
            </td>
            <td class="border-around text-center strap"><b>&nbsp;</b></td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[3].standar }}</b>
            </td>
            <td class="border-around text-center">
              <b>{{ competenciesLeft[3].score }}</b>
            </td>
            <td
              :class="
                competenciesLeft[3].score - competenciesLeft[3].standar < 0
                  ? 'border-around text-center val-min'
                  : competenciesLeft[3].score - competenciesLeft[3].standar == 0
                  ? 'border-around text-center'
                  : 'border-around text-center val-plus'
              "
            >
              <b>{{
                competenciesLeft[3].score - competenciesLeft[3].standar
              }}</b>
            </td>
          </tr>
        </table>
        <div class="d-flex">
          <p class="text-p">
            Catatan: Lihat kamus kompetensi untuk level yang lebih lengkap
            (1-5), sebelum mencapai level di atasnya maka level kompetensi
            sebelumnya harus terpenuhi terlebih dahulu
          </p>
        </div>
      </div>
      <!-- PAGE 3 -->
      <div v-if="page == 3 || isFullPage">
        <header class="d-flex justify-end mb-3">
          <b
            ><u>Nama : {{ person.nama_lengkap }}</u></b
          >
        </header>
        <section class="pa-2 primary-color mb-3">
          <h3 class="white--text text-center">
            <b>RECOMMENDATION BASED ON COMPETENCY LEVEL</b>
          </h3>
        </section>
        <!-- <div> -->
        <div class="d-flex justify-center">
          <section class="d-flex">
            <div :class="actualInPercent <= 60 ? 'picked ' : 'critical'">
              <div class="text-center">
                <h6 class="text-center line-height">CRITICAL</h6>
                <p class="text-center text-p ma-0">≤ 60%</p>
              </div>
            </div>
            <div
              :class="
                actualInPercent > 60 && actualInPercent <= 75
                  ? 'picked'
                  : 'intens'
              "
            >
              <div class="text-center">
                <h6 class="text-center line-height">INTENSIVE DEVELOPMENT</h6>
                <p class="text-center text-p ma-0">61 - 75%</p>
              </div>
            </div>
            <div
              :class="
                actualInPercent > 75 && actualInPercent <= 90
                  ? 'picked'
                  : 'near'
              "
            >
              <div class="text-center">
                <h6 class="text-center line-height">NEAR TO FIT</h6>
                <p class="text-center text-p ma-0">76 - 90%</p>
              </div>
            </div>
            <div
              :class="
                actualInPercent > 90 && actualInPercent <= 100
                  ? 'picked'
                  : 'competen'
              "
            >
              <div class="text-center">
                <h6 class="text-center line-height">COMPETENCY FIT</h6>
                <p class="text-center text-p ma-0">91 - 100%</p>
              </div>
            </div>
            <div :class="actualInPercent > 100 ? 'picked' : 'above'">
              <div class="text-center">
                <h6 class="text-center line-height">ABOVE STANDARD</h6>
                <p class="text-center text-p ma-0">> 100%</p>
              </div>
            </div>
          </section>
        </div>
        <div class="d-flex">
          <div class="half d-flex justify-center mt-5">
            <div class="_80w">
              <h4 class="text-center line-height"><i> ABOVE STANDARD</i></h4>
              <p class="text-p">
                <i
                  >Memiliki value dan competency melebihi standar yang
                  ditetapkan.</i
                >
              </p>
              <h4 class="text-center line-height"><i> COMPETENCY FIT</i></h4>
              <p class="text-p">
                <i
                  >Memiliki value dan competency yang sesuai dengan standar yang
                  ditetapkan.</i
                >
              </p>
              <h4 class="text-center line-height"><i> NEAR TO FIT</i></h4>
              <p class="text-p">
                <i
                  >Memerlukan pengembangan, sangat terbuka peluang untuk
                  dikembangkan</i
                >
              </p>
            </div>
          </div>
          <div class="half d-flex justify-center mt-5">
            <div class="_80w">
              <h4 class="text-center line-height">
                <i> INTENSIVE DEVELOPMENT</i>
              </h4>
              <p class="text-p">
                <i
                  >Memerlukan pengembangan intensif agar lebih sesuai dengan
                  tuntutan standar value dan competency.</i
                >
              </p>
              <h4 class="text-center line-height"><i> CRITICAL</i></h4>
              <p class="text-p">
                <i>Memerlukan usaha luar biasa untuk pengembangannya.</i>
              </p>
            </div>
          </div>
        </div>
        <section class="pa-2 primary-color mb-3">
          <h3 class="white--text text-center">
            <b>COMPETENCY AND VALUE RECAP</b>
          </h3>
        </section>
        <div class="d-flex justify-center my-7">
          <div class="circle pa-3" v-for="(item, idx) in recap" :key="idx">
            <div>
              <p class="text-center mb-1 text-more-small">
                {{ item.title }}
              </p>
              <h2 class="text-center green--text">{{ item.val }}</h2>
            </div>
          </div>
        </div>
        <div v-if="$route.params.periode == 'baseline'">
          <section class="pa-2 primary-color mb-3">
            <h3 class="white--text text-center">
              <b>PROFILE SUMMARY</b>
            </h3>
          </section>
          <div class="d-flex justify-center my-7">
            <div class="d-flex _80w">
              <div class="_40w d-flex justify-center">
                <section>
                  <div class="mb-7">
                    <p class="font-weight-black font-italic text-right">
                      IQ Score
                    </p>
                  </div>
                  <div>
                    <p class="font-weight-black font-italic text-right">
                      Category
                    </p>
                  </div>
                </section>
                <section class="d-flex mx-3">
                  <div class="border-twin"></div>
                </section>
                <section v-if="resultIq">
                  <div class="mb-7">{{ resultIq.iq }}</div>
                  <div>{{ resultIq.kategori }}</div>
                </section>
              </div>
              <div class="d-flex">
                <section>
                  <div class="mb-7">
                    <p class="font-weight-black font-italic text-right">
                      Psychological Resilience
                    </p>
                  </div>
                  <div>
                    <p class="font-weight-black font-italic text-right">
                      DISC Personality Type
                    </p>
                  </div>
                </section>
                <div class="border-twin mx-3"></div>
                <section>
                  <div class="mb-7">{{ categoryPsychologicalResilience }}</div>
                  <div>{{ dataDisc.aspec }}</div>
                </section>
              </div>
            </div>
          </div>
          <section class="pa-2 primary-color mb-3">
            <h3 class="white--text text-center">
              <b>DISC Personality Graph</b>
            </h3>
          </section>
          <div class="d-flex justify-center mb-7">
            <DiscGraph v-bind:graph="graph" />
          </div>
        </div>
        <div
          v-if="
            $route.params.periode == 'midline' ||
            $route.params.periode == 'endline'
          "
        >
          <section class="pa-2 primary-color mb-7">
            <h3 class="white--text text-center">
              <b>PROFILE DESCRIPTION</b>
            </h3>
          </section>
          <div>
            <div class="green-line"></div>
            <div class="bg-white">
              <h4>
                <i>Psychological Resilience </i>
                {{ categoryPsychologicalResilience }}
              </h4>
            </div>
          </div>
          <div class="ma-3">
            <p class="text-justify">
              <!-- <span>
                {{
                `&nbsp; &nbsp; &nbsp; &nbsp; ${
                  person.jenis_kelamin == "Laki-laki" ? "Sdr." : "Sdri."
                } ${person.nama_lengkap}`
              }}
              </span> -->
              <span v-html="resilience"></span>
            </p>
          </div>
        </div>
      </div>
      <!-- PAGE 4 -->
      <div v-if="page == 4 || isFullPage">
        <header class="d-flex justify-end mb-3">
          <b
            ><u>Nama : {{ person.nama_lengkap }}</u></b
          >
        </header>
        <div>
          <div class="green-line"></div>
          <div class="bg-white">
            <h3>VALUE</h3>
          </div>
        </div>
        <div class="mx-3">
          <p class="text-justify">
            <!-- {{
              `&nbsp; &nbsp; &nbsp; &nbsp; ${
                person.jenis_kelamin == "Laki-laki" ? "Sdr." : "Sdri."
              } ${person.nama_lengkap}`
            }} -->
            <span v-html="value"></span>
          </p>
        </div>
        <div class="mt-5">
          <div class="green-line"></div>
          <div class="bg-white">
            <h3>COMPETENCY</h3>
          </div>
        </div>
        <div class="mx-3">
          <p class="text-justify">
            <!-- <span>
            {{
              `&nbsp; &nbsp; &nbsp; &nbsp; ${
                person.jenis_kelamin == "Laki-laki" ? "Sdr." : "Sdri."
              } ${person.nama_lengkap}`
            }}
            </span> -->
            <span v-html="competency"></span>
          </p>
        </div>
      </div>
      <!-- PAGE 5 -->
      <div v-if="page == 5 || isFullPage">
        <header class="d-flex justify-end mb-3">
          <b
            ><u>Nama : {{ person.nama_lengkap }}</u></b
          >
        </header>
        <div class="mt-5" v-if="$route.params.periode == 'baseline'">
          <div class="primary-color px-3">
            <h4 class="white--text">PSYCHOLOGICAL RESILIENCE DESCRIPTIONS</h4>
          </div>
          <div class="py-5 px-3" style="border: 1px black solid">
            <p class="ma-0 text-justify" v-html="resilience"></p>
          </div>
        </div>
        <div class="mt-5 mb-7">
          <div class="primary-color px-3">
            <h4 class="white--text">SUMMARY</h4>
          </div>
          <!-- tidak lolos -->
          <div
            class="py-3 px-3"
            style="border: 1px black solid"
            v-if="summaryValues || summaryCompetency"
          >
            <p class="text-justify ma-0">
              Berdasarkan hasil asesmen
              {{ person.jenis_kelamin == "Laki-laki" ? "Sdr." : "Sdri." }}
              {{ person.nama_lengkap }} perlu meningkatkan
              <span v-if="summaryValues"><i>value</i> yaitu </span>
              <span v-html="summaryValues"></span>
              {{ summaryValues && summaryCompetency ? " serta " : ""
              }}<span v-if="summaryCompetency"><i>competency </i>yaitu </span>
              <span
                v-html="summaryCompetency ? `${summaryCompetency}` : ''"
              ></span>

              agar dapat memenuhi standar minimal penerima Beasiswa TELADAN
              {{ person.tahun_tf }}
            </p>
          </div>
          <!-- lolos -->
          <div class="py-5 px-3" style="border: 1px black solid" v-else>
            <p class="text-justify">
              Berdasarkan hasil asesmen
              {{ person.jenis_kelamin == "Laki-laki" ? "Sdr." : "Sdri." }}
              {{ person.nama_lengkap }} memenuhi standar minimal untuk
              <i>value</i> dan <i>competency </i>
              <span v-if="$route.params.periode == 'baseline'">
                yang dipersyaratkan sebagai penerima Beasiswa TELADAN
                {{ person.tahun_tf ? person.tahun_tf : "" }}.</span
              >
              <!-- <span v-if="$route.params.periode !== 'baseline'"
                ><i>{{ $route.params.periode }}</i> peserta Beasiswa TELADAN
                {{ person.tahun_tf ? person.tahun_tf : "" }} untuk menuju
                program pengembangan selanjutnya.</span
              > -->
            </p>
          </div>
        </div>
        <section class="d-flex justify-end">
          <div style="width: 250px">
            <p class="ma-0 text-center">
              Bandung, {{ $date(created_at).format("DD MMMM YYYY") }}
            </p>
            <p class="ma-0 text-center">Psikolog</p>
            <div class="d-flex justify-center">
              <img
                src="../../../../assets/img/ttd.png"
                height="100px"
                width="150px"
                alt=""
              />
            </div>
            <p class="ma-0 text-center">
              <u>Medianta Tarigan, M.Psi., Psikolog</u>
            </p>
            <p class="ma-0 text-center">No. SIPP: 1024-20-2-2</p>
          </div>
        </section>
      </div>
      <div
        class="mt-5"
        v-if="page == 6 || (isFullPage && $route.params.periode == 'baseline')"
      >
        <header class="d-flex justify-end mb-3">
          <b
            ><u>Nama : {{ person.nama_lengkap }}</u></b
          >
        </header>
        <div class="primary-color px-3 mb-7">
          <h4 class="white--text">PROFILE DESCRIPTIONS</h4>
        </div>
        <div v-if="resultIq">
          <div class="green-line"></div>
          <div class="bg-white">
            <p class="ma-0">
              Kategory IQ : <b>{{ resultIq.kategori }}</b>
            </p>
          </div>
          <p class="mt-1 text-justify">
            Berdasarkan hasil pemeriksaan psikologis,
            {{ person.jenis_kelamin == "Laki-laki" ? "Sdr." : "Sdri." }}
            {{ person.nama_lengkap }} memiliki inteligensi pada kategori
            {{ resultIq.kategori }}. Hal ini menunjukkan bahwa
            {{ resultIq.deskripsi }}
          </p>
        </div>
        <div class="mt-7">
          <div class="green-line"></div>
          <div class="bg-white">
            <p class="ma-0">
              Tipe DISC : <b>{{ dataDisc.aspec }}</b>
            </p>
          </div>
          <p class="mt-1 text-justify">
            {{ description_disc }}
          </p>
        </div>
      </div>
    </div>
    <section class="d-flex justify-center my-3" v-if="!isFullPage">
      <v-btn
        depressed
        class="mr-2 custom-paginate"
        :dark="page == 1 ? true : false"
        :color="page == 1 ? `green` : `white`"
        @click="switchPage(1)"
        >1</v-btn
      >
      <v-btn
        depressed
        class="mr-2 custom-paginate"
        :dark="page == 2 ? true : false"
        :color="page == 2 ? `green` : `white`"
        @click="switchPage(2)"
        >2</v-btn
      >
      <v-btn
        depressed
        class="mr-2 custom-paginate"
        :dark="page == 3 ? true : false"
        :color="page == 3 ? `green` : `white`"
        @click="switchPage(3)"
        >3</v-btn
      >
      <v-btn
        depressed
        class="mr-2 custom-paginate"
        :dark="page == 4 ? true : false"
        :color="page == 4 ? `green` : `white`"
        @click="switchPage(4)"
        >4</v-btn
      >
      <v-btn
        depressed
        class="mr-2 custom-paginate"
        :dark="page == 5 ? true : false"
        :color="page == 5 ? `green` : `white`"
        @click="switchPage(5)"
        >5</v-btn
      >
      <v-btn
        v-if="$route.params.periode == 'baseline'"
        depressed
        class="mr-2 custom-paginate"
        :dark="page == 6 ? true : false"
        :color="page == 6 ? `green` : `white`"
        @click="switchPage(6)"
        >6</v-btn
      >
    </section>
  </div>
</template>

<script>
import DiscGraph from "../../../../views/Report/view/discGraph.vue";
export default {
  name: "BaselineAssessment",
  components: { DiscGraph },
  props: [
    "values",
    "competenciesRight",
    "competenciesLeft",
    "person",
    "dataScore",
    "resultIq",
    "dataChart",
    "dataDisc",
    "dataSaran",
    "actualInPercent",
    "recap",
    "graph",
    "resilience",
    "value",
    "competency",
    "summaryCompetency",
    "summaryValues",
    "created_at",
    "description_disc",
    "periode_pemeriksaan",
    "categoryPsychologicalResilience",
  ],
  data() {
    return {
      page: 1,
      items: [
        { attr: "Multiple Page", value: false },
        { attr: "Full Page", value: true },
      ],
      isFullPage: false,
    };
  },
  computed: {},
  methods: {
    switchPage(page) {
      this.page = page;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.primary-color {
  background: #006341;
}
.secondary-color {
  background: #b3a369;
}
.temp_ {
  width: 900px;
  background: white;
  padding: 40px;
}
.table-identity {
  border-collapse: collapse;
  border: #006341 1px solid;
}
.border-around {
  /* border-collapse: collapse; */
  border: 1px solid black;
  padding: 5px;
}
.strap {
  background: #e2efd9;
}
.val-min {
  background: #f7caac;
}
.val-plus {
  background: #a8d08d;
}
.text-p {
  font-size: small;
}

.critical {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4f0dc;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 25px;
}
.intens {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c5e0b4;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 25px;
}
.near {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a8d18f;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 25px;
}
.competen {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #85be62;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 25px;
}
.above {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #385723;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 25px;
}
.picked {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6c619;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 25px;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #85be62 solid;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-right: 20px;
}
.border-twin {
  border-left: 1px #85be62 solid;
  border-right: 1px #85be62 solid;
  margin: 0 2px;
  padding: 1px;
}
.line-height {
  line-height: normal;
}
.half {
  width: 50%;
}
._40w {
  width: 45%;
}
.green-line {
  background: #85be62;
  /* height: 10px; */
  padding: 2px;
  width: 100%;
}
.bg-white {
  background: white;
  margin-top: -26px;
  padding: 10px 10px 0 0;
  width: max-content;
}
.custom-paginate {
  min-width: 0 !important;
}
.text-more-small {
  font-size: 10px;
}
</style>
