<template>
  <div>
    <div class="pa-3" v-if="!loading">
      <div class="mb-3">
        <v-btn small fab depressed color="green" dark @click="getBack">
          <v-icon>mdi-reply</v-icon>
        </v-btn>
      </div>
      <section class="d-flex justify-space-between" v-if="score">
        <div class="d-flex align-center">
          <v-icon class="mr-1" color="green">mdi-file-document-outline</v-icon>
          <h1>Report</h1>
        </div>

        <div>
          <div v-if="role === 'ADMIN_ASSESSMENT' || role === 'SUPERADMIN'">
            <v-btn
              dark
              v-if="score.code == 404"
              depressed
              class="text-capitalize"
              color="green"
              :to="`/create/report/${$route.params.id}/${periode}`"
              ><v-icon>mdi-plus</v-icon>Add Report</v-btn
            >
          </div>
          <div v-if="score.code !== 404">
            <v-btn
              v-if="role === 'ADMIN_ASSESSMENT' || role === 'SUPERADMIN'"
              dark
              class="text-capitalize"
              depressed
              color="green"
              :to="`/update/report/${$route.params.id}/${periode}`"
              ><v-icon>mdi-pencil</v-icon>Update</v-btn
            >
            <v-btn
              id="dwnld"
              v-if="!isDownloading && !isDownloadError"
              dark
              depressed
              class="ml-3 text-capitalize"
              color="green"
              @click="dialogDownloadReport = true"
            >
              <v-icon>mdi-download</v-icon>
              Download Pdf
            </v-btn>
            <v-btn
              id="relod"
              v-if="isDownloadError"
              dark
              depressed
              class="ml-3 text-capitalize"
              color="green"
              @click="generateReport"
            >
              <v-icon> mdi-file-pdf </v-icon>
              Generate Pdf
            </v-btn>
            <v-tooltip bottom v-if="isDownloading">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isDownloading"
                  v-bind="attrs"
                  v-on="on"
                  dark
                  depressed
                  class="ml-3 text-capitalize"
                  color="green"
                >
                  <v-progress-circular
                    :size="25"
                    class="mr-2"
                    indeterminate
                  ></v-progress-circular>
                  generating pdf
                </v-btn>
              </template>
              <span> <PrintingSM /> </span>
            </v-tooltip>
          </div>
        </div>
      </section>
      <v-tabs v-model="tab" color="green" background-color="#EDF1F0">
        <v-tab
          href="#tab1"
          class="text-capitalize"
          @click="setType('tab1')"
          v-if="role !== 'SCHOLAR'"
          ><h3>Assessment</h3></v-tab
        >
        <v-tab href="#tab2" class="text-capitalize" @click="setType('tab2')"
          ><h3>Scholar</h3></v-tab
        >
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab" style="background: none" class="mt-3">
        <v-tab-item value="tab1">
          <div v-if="score">
            <div v-if="score.code !== 404" class="d-flex justify-center pa-3">
              <BaselineAssesment
                v-bind:values="values"
                v-bind:competenciesRight="competenciesRight"
                v-bind:competenciesLeft="competenciesLeft"
                v-bind:person="score.data_scholar"
                v-bind:dataScore="dataScore"
                v-bind:resultIq="score.result_iq"
                v-bind:dataChart="dataChart"
                v-bind:dataDisc="dataDisc"
                v-bind:dataSaran="score.saran_pengembangan"
                v-bind:actualInPercent="actualInPercent"
                v-bind:recap="recap"
                v-bind:graph="graph"
                v-bind:resilience="textResilience"
                v-bind:value="textValue"
                v-bind:competency="textCompetency"
                v-bind:summaryCompetency="summaryCompetency"
                v-bind:summaryValues="summaryValues"
                v-bind:created_at="score.data.created_at"
                v-bind:description_disc="score.data.deskripsi"
                v-bind:periode_pemeriksaan="periode_pemeriksaan"
                v-bind:categoryPsychologicalResilience="
                  categoryPsychologicalResilience
                "
              />
            </div>
            <div
              class="d-flex align-center justify-center flex-column"
              v-if="score.code == 404"
            >
              <img
                src="../../../assets/img/notFound.png"
                height="220px"
                alt="404"
              />
              <h2 class="text-center">{{ score.message }}</h2>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item value="tab2">
          <div v-if="score">
            <div v-if="score.code !== 404" class="pa-3">
              <BaselineScholar
                v-bind:values="values"
                :competenciesFull="competenciesFull"
                v-bind:competenciesRight="competenciesRight"
                v-bind:competenciesLeft="competenciesLeft"
                v-bind:person="score.data_scholar"
                v-bind:dataScore="dataScore"
                v-bind:resultIq="score.result_iq"
                v-bind:dataChart="dataChart"
                v-bind:dataDisc="dataDisc"
                v-bind:dataSaran="score.saran_pengembangan"
                v-bind:resilience="score.data.psychological_desc_scholar"
              />
            </div>
            <div
              class="d-flex align-center justify-center flex-column"
              v-if="score.code == 404"
            >
              <img
                src="../../../assets/img/notFound.png"
                height="220px"
                alt="404"
              />
              <h2 class="text-center">{{ score.message }}</h2>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div
      class="d-flex align-center justify-center flex-column"
      v-if="err.code === 500"
    >
      <img src="../../../assets/img/fileError.png" height="220px" alt="404" />
      <h2 class="text-center">{{ err.message }}</h2>
    </div>
    <div v-if="loading">
      <Loading />
    </div>
    <div class="pa-2">
      <SelectType
        @close="closeDialog"
        v-bind:dialogDownloadReport="dialogDownloadReport"
        v-bind:dataDownload="dataDownload"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import BaselineScholar from "../../../components/Report/view/Baseline.vue";
import SelectType from "../../../components/Report/Download/selectType.vue";
import BaselineAssesment from "../../../components/Report/view/Assesment/Baseline.vue";
import Loading from "../../../components/etc/loading-component/loadingLG.vue";
import PrintingSM from "../../../components/etc/loading-component/printingSM.vue";

export default {
  components: {
    BaselineScholar,
    SelectType,
    BaselineAssesment,
    Loading,
    PrintingSM,
  },
  name: "viewReport",
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("tab");
    next();
  },
  computed: {
    ...mapState({
      score: (state) => state.report.score,
      role: (state) => state.role,
    }),

    summaryValues() {
      let data = null;
      if (this.dataScore) {
        // eslint-disable-next-line no-unused-vars
        data = this.dataScore.filter((el, idx) => {
          return idx < 4;
        });
        let filtered = data.filter((item) => item.score - item.standar < 0);
        if (filtered.length > 1) {
          let last = filtered.pop();
          data = `${filtered.map((el) => {
            return ` <i>${el.name
              .replace(/_/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())}</i>`;
          })} dan <i>${last.name
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase())}</i>,`;
        } else if (filtered.length == 1) {
          data = `<i>${filtered[0].name
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase())}</i>,`;
        } else {
          data = null;
        }
      }
      return data;
    },
    summaryCompetency() {
      let data = null;
      if (this.dataScore) {
        // eslint-disable-next-line no-unused-vars
        data = this.dataScore.filter((el, idx) => {
          return idx > 3;
        });
        let filtered = data.filter((item) => {
          return item.score - item.standar < 0;
        });
        if (filtered.length > 1) {
          let last = filtered.pop();
          data = `${filtered.map((el) => {
            return ` <i>${el.name
              .replace(/_/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase())}</i>`;
          })} dan <i>${last.name
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase())}</i>,`;
        } else if (filtered.length == 1) {
          data = `<i>${filtered[0].name
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase())}</i>,`;
        } else {
          data = null;
        }
      }
      return data;
    },
    textValue() {
      let data = null;
      if (this.score) {
        if (this.score.data.competency_value) {
          data = `&nbsp; &nbsp; &nbsp; &nbsp; ${
            this.score.data_scholar.jenis_kelamin == "Laki-laki"
              ? "Sdr."
              : "Sdri."
          } ${
            this.score.data_scholar.nama_lengkap
          } ${this.score.data.competency_value
            .replace("<p", "<span")
            .replace("</p", "</span")}`;
        }
      }
      return data;
    },
    textCompetency() {
      let data = null;
      if (this.score) {
        if (this.score.data.competency_desc) {
          data = `&nbsp; &nbsp; &nbsp; &nbsp; ${
            this.score.data_scholar.jenis_kelamin == "Laki-laki"
              ? "Sdr."
              : "Sdri."
          } ${
            this.score.data_scholar.nama_lengkap
          } ${this.score.data.competency_desc
            .replace("<p", "<span")
            .replace("</p", "</span")}`;
        }
      }
      return data;
    },
    textResilience() {
      let data = null;
      if (this.score) {
        if (this.score.data.psychological_desc) {
          data = `${
            this.score.data_scholar.jenis_kelamin == "Laki-laki"
              ? "Sdr."
              : "Sdri."
          } ${
            this.score.data_scholar.nama_lengkap
          } ${this.score.data.psychological_desc
            .replace("<p", "<span")
            .replace("</p", "</span")}`;
        }
      }
      return data;
    },
    values() {
      let data = null;
      if (this.score) {
        // eslint-disable-next-line no-unused-vars
        data = this.score.gabungan.filter((el, idx) => {
          return idx < 4;
        });
      }
      return data;
    },
    competenciesRight() {
      let data = null;
      if (this.score) {
        // eslint-disable-next-line no-unused-vars
        data = this.score.gabungan.filter((el, idx) => {
          return idx > 3 && idx < 13;
        });
      }
      return data;
    },
    competenciesLeft() {
      let data = null;
      if (this.score) {
        // eslint-disable-next-line no-unused-vars
        data = this.score.gabungan.filter((el, idx) => {
          return idx > 12;
        });
      }
      return data;
    },
    competenciesFull() {
      let data = null;
      if (this.score) {
        // eslint-disable-next-line no-unused-vars
        data = this.score.gabungan.filter((el, idx) => {
          return idx > 3;
        });
      }
      return data;
    },
    dataChart() {
      let data = [];
      if (this.score) {
        for (const key in this.score.data_chart.count) {
          data.push(this.score.data_chart.count[key]);
        }
      }
      return data;
    },
    dataDisc() {
      let data = null;
      if (this.score) {
        if (this.score.disc_desc) {
          data = {
            aspec: this.score.disc_desc.aspek,
            personality: [
              {
                name: "Karakteristik",
                desc: this.score.disc_desc.karakteristik,
              },
              {
                name: "Tujuan",
                desc: this.score.disc_desc.tujuan,
              },
              {
                name: "Ketakutan",
                desc: this.score.disc_desc.ketakutan,
              },
              {
                name: "Menghadapi Tekanan",
                desc: this.score.disc_desc.tekanan,
              },
              {
                name: "Tindakan untuk meningkatkan performa",
                desc: this.score.disc_desc.tindakan,
              },
            ],
            img: this.score.disc_image,
          };
        }
      }
      return data;
    },
  },
  data() {
    return {
      tab: "tab1",
      periode: "",
      dataScore: null,
      idScholar: null,
      isDownloading: false,
      isDownloadError: false,
      dataDownload: null,
      dialogDownloadReport: false,
      actualInPercent: null,
      recap: [],
      graph: {},
      loading: false,
      err: "",
      periode_pemeriksaan: null,
      categoryPsychologicalResilience: null,
      generatingPdf: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.periode = this.$route.params.periode;
      this.getScore();
    },
  },
  mounted() {
    this.periode = this.$route.params.periode;
    let type = localStorage.getItem("tab");
    this.tab = type ? type : "tab1";
    this.getScore();
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    setType(type) {
      localStorage.setItem("tab", type);
    },
    getScore(type) {
      this.loading = true;
      let data = {
        id: this.$route.params.id,
        periode: type ? type : this.periode,
      };
      this.$store
        .dispatch("report/viewScore", data)
        .then((data) => {
          if (data.code == 404) {
            setTimeout(() => (this.loading = false), 100);
          } else {
            this.generateReport();

            let score = data.data;
            this.categoryPsychologicalResilience =
              score.psychological_resilience;

            let isYearSame =
              this.$date(score.range_periode[0]).format("YYYY") ==
              this.$date(score.range_periode[1]).format("YYYY");
            let isMontSame =
              this.$date(score.range_periode[0]).format("MM") ==
              this.$date(score.range_periode[1]).format("MM");
            let dateRangeTxt = "";
            if (isYearSame) {
              if (isMontSame) {
                dateRangeTxt = `${this.$date(score.range_periode[1]).format(
                  "MMMM YYYY"
                )}`;
              } else {
                dateRangeTxt = `${this.$date(score.range_periode[0]).format(
                  "MMMM"
                )} - ${this.$date(score.range_periode[1]).format("MMMM YYYY")}`;
              }
            } else {
              dateRangeTxt = `${this.$date(score.range_periode[0]).format(
                "MMMM YYYY"
              )} - ${this.$date(score.range_periode[1]).format("MMMM YYYY")}`;
            }
            this.periode_pemeriksaan = {
              periode: dateRangeTxt,
              tahun: this.$date(score.range_periode[0]).format("YYYY"),
            };
            let result = data.gabungan;
            let bhv = data.behavior_desc;
            let need = 0;
            let meet = 0;
            let above = 0;

            result.forEach((el) => {
              bhv.forEach((item) => {
                if (el.name == item.aspek) {
                  el["behavior"] = item.behavior;
                }
              });
              switch (el.name) {
                case "integrity":
                  el["score"] = score["value_integrity"];
                  break;
                case "strive_for_excellence":
                  el["score"] = score["value_strive"];
                  break;
                case "grit":
                  el["score"] = score["value_grit"];
                  break;
                case "caring":
                  el["score"] = score["value_caring"];
                  break;
                case "self_awareness":
                  el["score"] = score["leader_aware"];
                  el["type"] = "self_awareness";
                  break;
                case "self_purpose":
                  el["score"] = score["leader_purpose"];
                  el["type"] = "leadership";
                  break;
                case "influence_people":
                  el["score"] = score["leader_influence"];
                  el["type"] = "leadership";
                  break;
                case "empowering_others":
                  el["score"] = score["leader_empower"];
                  el["type"] = "leadership";
                  break;
                case "planning":
                  el["score"] = score["execution_planning"];
                  el["type"] = "execution";
                  break;
                case "resources_management":
                  el["score"] = score["execution_resources"];
                  el["type"] = "execution";
                  break;
                case "agility":
                  el["score"] = score["execution_agility"];
                  el["type"] = "execution";
                  break;
                case "decision_making":
                  el["score"] = score["execution_decision"];
                  el["type"] = "execution";
                  break;
                case "relationships_building":
                  el["score"] = score["collab_relationship"];
                  el["type"] = "collaboration";
                  break;
                case "communication":
                  el["score"] = score["collab_communication"];
                  el["type"] = "collaboration";
                  break;
                case "team_work":
                  el["score"] = score["collab_teamwork"];
                  el["type"] = "collaboration";
                  break;
                case "innovation":
                  el["score"] = score["collab_innovation"];
                  el["type"] = "innovation";
                  break;
                case "global_citizenship":
                  el["score"] = score["collab_gc"];
                  el["type"] = "global_citizenship";
                  break;
              }
              let gap = el.score - el.standar;
              if (gap < 0) {
                need++;
              } else if (gap == 0) {
                meet++;
              } else {
                above++;
              }
            });

            this.graph["most_d"] = score.graph_satu_d;
            this.graph["most_i"] = score.graph_satu_i;
            this.graph["most_s"] = score.graph_satu_s;
            this.graph["most_c"] = score.graph_satu_c;
            this.graph["least_d"] = score.graph_dua_d;
            this.graph["least_i"] = score.graph_dua_i;
            this.graph["least_s"] = score.graph_dua_s;
            this.graph["least_c"] = score.graph_dua_c;
            this.graph["change_d"] = score.graph_tiga_d;
            this.graph["change_i"] = score.graph_tiga_i;
            this.graph["change_s"] = score.graph_tiga_s;
            this.graph["change_c"] = score.graph_tiga_c;
            this.dataScore = result;
            let actual = this.dataScore
              .map((el) => {
                return el.score;
              })
              .reduce((a, b) => a + b, 0);
            let req = this.dataScore
              .map((el) => {
                return el.standar;
              })
              .reduce((a, b) => a + b, 0);
            // console.log(`actual: ${actual}, req: ${req}`);
            this.actualInPercent = (actual / req) * 100;
            this.recap = [
              {
                title: "NEED DEVELOPMENT",
                val: need,
              },
              {
                title: "MEET REQUIREMENT",
                val: meet,
              },
              {
                title: "ABOVE DEVELOPMENT",
                val: above,
              },
            ];
            setTimeout(() => (this.loading = false), 500);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.err = err;
        });
    },
    generateReport() {
      let data = {
        id_scholar: this.$route.params.id,
        periode: this.$route.params.periode,
      };

      this.isDownloadError = false;
      this.isDownloading = true;
      this.$store
        .dispatch("report/downloadReport", data)
        .then((data) => {
          this.isDownloading = false;
          if (data.status == "success") {
            this.dataDownload = data;
            this.Swal("success", data.message);
          } else {
            this.Swal("error", data.message);
            this.isDownloadError = true;
          }
          // console.log(data);
        })
        .catch((err) => {
          this.isDownloadError = true;
          this.isDownloading = false;
          this.Swal("warning", err.response.data.message);
        });
    },
    closeDialog() {
      this.dialogDownloadReport = false;
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        showCloseButton: true,
        toast: true,
      });
    },
  },
};
</script>

<style>
.tooltip-background-color {
  background: #000 !important;
}
</style>
