<template>
  <div class="d-flex align-center flex-column">
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="V.1 0821 Create PDF 1">
        <g id="Layer 2">
          <g id="Layer 3">
            <path
              id="Vector"
              d="M104.741 35.2689H50.2031C48.0043 35.2689 46.2219 37.0513 46.2219 39.2501V118.788C46.2219 120.986 48.0043 122.769 50.2031 122.769H104.741C106.939 122.769 108.722 120.986 108.722 118.788V39.2501C108.722 37.0513 106.939 35.2689 104.741 35.2689Z"
              fill="#00331E"
            />
          </g>
          <g id="tempPaper">
            <path
              id="papper"
              d="M102.472 27.4155H52.4719V100.894H102.472V27.4155Z"
              fill="#F2EEE2"
            />
            <path
              id="piecePapper"
              d="M52.4719 27.4155V70.6748C54.1944 71.0008 55.9437 71.165 57.6969 71.1655C76.8844 71.1655 92.5562 51.7905 93.5781 27.4155H52.4719Z"
              fill="#F9F7F2"
            />
            <path
              id="Vector_2"
              d="M78.75 91.0092H79.6875V91.4842H77.325V91.0092H78.2469V88.5686H78.75V91.0092Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_3"
              d="M76.7781 91.4842H74.6156V91.028H76.2656V90.2686H74.8031V89.8123H76.2656V89.028H74.5969V88.5686H76.7844L76.7781 91.4842Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_4"
              d="M73.9875 91.4842H73.475V89.0373H71.9406V88.5686H73.9875V91.4842Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_5"
              d="M70.375 91.5061H69.9L68.6156 88.5686H69.1562L69.4531 89.2748H70.8312L71.1437 88.5686H71.6687L70.375 91.5061ZM69.6406 89.728L70.1437 90.8936L70.6437 89.728H69.6406Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_6"
              d="M68.125 91.4842H67.0375C66.8382 91.4992 66.6379 91.473 66.4492 91.4071C66.2605 91.3413 66.0874 91.2372 65.9408 91.1014C65.7941 90.9656 65.6771 90.801 65.5969 90.6179C65.5168 90.4348 65.4753 90.2372 65.475 90.0373C65.4749 89.8368 65.5161 89.6384 65.596 89.4545C65.6758 89.2706 65.7927 89.1051 65.9392 88.9682C66.0857 88.8313 66.2588 88.7261 66.4478 88.6589C66.6367 88.5918 66.8374 88.5642 67.0375 88.5779H68.125V91.4842ZM67.6125 91.0186V89.0373H67.0375C66.9044 89.0265 66.7706 89.0442 66.645 89.0892C66.5193 89.1342 66.4047 89.2056 66.3089 89.2985C66.213 89.3914 66.1381 89.5037 66.0891 89.6279C66.0402 89.7521 66.0183 89.8853 66.025 90.0186V90.0186C66.0199 90.1519 66.0429 90.2848 66.0923 90.4087C66.1417 90.5326 66.2164 90.6449 66.3118 90.7382C66.4072 90.8315 66.521 90.9038 66.646 90.9505C66.7709 90.9971 66.9043 91.0171 67.0375 91.0092L67.6125 91.0186Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_7"
              d="M64.0281 91.5061H63.5531L62.2719 88.5686H62.8125L63.1093 89.2748H64.4875L64.8 88.5686H65.3125L64.0281 91.5061ZM63.2969 89.728L63.8 90.8936L64.3 89.728H63.2969Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_8"
              d="M61.7687 91.4842H61.2937L59.7312 89.4686V91.4842H59.2281V88.5686H59.6562L61.2656 90.6436V88.5686H61.7687V91.4842Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_9"
              d="M79.4781 86.4844H78.1781C77.8706 86.5021 77.5685 86.3976 77.3375 86.1938C77.1787 86.0248 77.0924 85.8005 77.0969 85.5688C77.0902 85.3642 77.1558 85.1637 77.282 85.0026C77.4083 84.8415 77.5872 84.7299 77.7875 84.6875L77 83.5812H77.6094L78.325 84.6H78.9656V83.5812H79.4781V86.4844ZM78.2281 85.0406C77.8625 85.0406 77.6281 85.2312 77.6281 85.5281C77.6281 85.8406 77.8531 86.0125 78.2313 86.0125H78.9781V85.0344L78.2281 85.0406Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_10"
              d="M76.6843 84.6622C76.6919 84.8086 76.6699 84.9549 76.6197 85.0925C76.5695 85.2302 76.492 85.3563 76.392 85.4633C76.2919 85.5704 76.1713 85.6562 76.0374 85.7156C75.9035 85.775 75.759 85.8068 75.6125 85.8091C74.9125 85.8091 74.5562 85.2591 74.5562 84.6247C74.5562 84.581 74.5562 84.5341 74.5562 84.4841H76.1718C76.1556 84.33 76.0826 84.1874 75.9671 84.084C75.8516 83.9806 75.7018 83.9239 75.5468 83.9247C75.4346 83.9245 75.3235 83.9478 75.2207 83.993C75.1179 84.0382 75.0257 84.1043 74.95 84.1872L74.6531 83.9247C74.7612 83.7914 74.8987 83.6847 75.0548 83.6132C75.2109 83.5416 75.3814 83.5071 75.5531 83.5122C75.7046 83.5073 75.8555 83.5338 75.9962 83.5901C76.1369 83.6464 76.2644 83.7313 76.3707 83.8393C76.477 83.9474 76.5598 84.0763 76.6137 84.2179C76.6677 84.3596 76.6918 84.5109 76.6843 84.6622V84.6622ZM75.0562 84.8247C75.0545 84.9753 75.1123 85.1205 75.2171 85.2288C75.3218 85.337 75.465 85.3996 75.6156 85.4029C75.7663 85.3966 75.9091 85.3335 76.0152 85.2263C76.1213 85.119 76.1829 84.9755 76.1875 84.8247H75.0562Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_11"
              d="M74.0625 85.7687H73.5594V85.4031C73.4797 85.5299 73.369 85.6343 73.2378 85.7065C73.1066 85.7787 72.9591 85.8162 72.8094 85.8156C72.6663 85.8114 72.5256 85.7781 72.3958 85.7178C72.266 85.6574 72.1499 85.5712 72.0546 85.4644C71.9593 85.3577 71.8867 85.2326 71.8413 85.0969C71.7959 84.9612 71.7787 84.8176 71.7906 84.675V84.675C71.7787 84.5323 71.7959 84.3888 71.8413 84.253C71.8867 84.1173 71.9593 83.9922 72.0546 83.8855C72.1499 83.7787 72.266 83.6925 72.3958 83.6322C72.5256 83.5718 72.6663 83.5385 72.8094 83.5343C72.9567 83.5318 73.1023 83.5659 73.2332 83.6336C73.3641 83.7012 73.4761 83.8002 73.5594 83.9218V82.9124H74.0625V85.7687ZM72.3031 84.6656C72.2959 84.7532 72.3065 84.8415 72.3343 84.9249C72.3622 85.0083 72.4066 85.0852 72.4651 85.1509C72.5235 85.2167 72.5947 85.2699 72.6743 85.3073C72.7539 85.3447 72.8402 85.3656 72.9281 85.3687C73.0154 85.3639 73.1009 85.3419 73.1797 85.3039C73.2585 85.2659 73.329 85.2127 73.3871 85.1473C73.4452 85.082 73.4898 85.0058 73.5183 84.9231C73.5468 84.8404 73.5586 84.7529 73.5531 84.6656V84.6656C73.5586 84.5783 73.5468 84.4908 73.5183 84.4081C73.4898 84.3254 73.4452 84.2492 73.3871 84.1838C73.329 84.1185 73.2585 84.0652 73.1797 84.0272C73.1009 83.9892 73.0154 83.9672 72.9281 83.9625C72.8384 83.962 72.7497 83.9804 72.6676 84.0164C72.5855 84.0525 72.5119 84.1053 72.4515 84.1716C72.3911 84.2379 72.3453 84.3161 72.3171 84.4012C72.2888 84.4863 72.2787 84.5764 72.2875 84.6656H72.3031Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_12"
              d="M71.3718 84.6561C71.3718 84.9669 71.2484 85.265 71.0286 85.4848C70.8088 85.7045 70.5108 85.828 70.2 85.828C69.8892 85.828 69.5911 85.7045 69.3713 85.4848C69.1515 85.265 69.0281 84.9669 69.0281 84.6561C69.0284 84.503 69.0594 84.3514 69.1191 84.2104C69.1789 84.0694 69.2662 83.9417 69.3759 83.8349C69.4856 83.7281 69.6156 83.6442 69.7582 83.5883C69.9008 83.5324 70.0531 83.5055 70.2062 83.5093C70.3587 83.5055 70.5105 83.5324 70.6524 83.5885C70.7943 83.6445 70.9234 83.7286 71.0322 83.8356C71.1409 83.9426 71.227 84.0704 71.2854 84.2113C71.3437 84.3523 71.3731 84.5036 71.3718 84.6561V84.6561ZM69.5312 84.6561C69.5274 84.7473 69.5421 84.8382 69.5742 84.9236C69.6064 85.0089 69.6555 85.0869 69.7185 85.1528C69.7815 85.2187 69.8572 85.2713 69.941 85.3073C70.0248 85.3433 70.115 85.362 70.2062 85.3624C70.2964 85.3634 70.3859 85.3458 70.469 85.3107C70.5522 85.2756 70.6272 85.2237 70.6893 85.1583C70.7515 85.0929 70.7995 85.0154 70.8304 84.9306C70.8613 84.8458 70.8743 84.7556 70.8687 84.6655V84.6655C70.8725 84.575 70.858 84.4848 70.8262 84.4C70.7944 84.3152 70.7458 84.2378 70.6834 84.1722C70.621 84.1066 70.5461 84.0542 70.463 84.0181C70.3799 83.9821 70.2905 83.9631 70.2 83.9624C70.1096 83.961 70.0199 83.9781 69.9365 84.0128C69.8531 84.0475 69.7777 84.099 69.715 84.1641C69.6522 84.2291 69.6036 84.3064 69.572 84.391C69.5404 84.4757 69.5265 84.5659 69.5312 84.6561V84.6561Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_13"
              d="M68.5156 85.7688H68.0125V85.272C67.9597 85.4351 67.8545 85.5763 67.7132 85.6735C67.5719 85.7707 67.4025 85.8185 67.2313 85.8095V85.2782H67.2625C67.7031 85.2782 68.0125 84.9907 68.0125 84.4063V83.5688H68.5156V85.7688Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_14"
              d="M66.6906 84.1782V85.3345H66.9687V85.7688H66.6906V86.372H66.1875V85.7688H65.5938V85.3345H66.1875V84.2563C66.1933 84.2192 66.1902 84.1812 66.1786 84.1454C66.1671 84.1097 66.1473 84.0771 66.1208 84.0503C66.0944 84.0236 66.0621 84.0034 66.0264 83.9915C65.9908 83.9795 65.9528 83.976 65.9156 83.9813C65.807 83.9809 65.6998 84.0067 65.6031 84.0563V83.6438C65.7415 83.5667 65.8979 83.5279 66.0563 83.5313C66.1434 83.5196 66.2321 83.5284 66.3153 83.5569C66.3985 83.5855 66.4739 83.6331 66.5355 83.6959C66.597 83.7587 66.6431 83.835 66.6701 83.9187C66.697 84.0024 66.704 84.0913 66.6906 84.1782V84.1782Z"
              fill="#E5E1D6"
            />
            <path
              id="Vector_15"
              d="M89.0625 94.403C90.6044 94.3967 92.1027 93.8908 93.3328 92.9611C94.563 92.0315 95.4587 90.7282 95.8857 89.2466C96.3127 87.765 96.2479 86.1849 95.7012 84.7432C95.1545 83.3014 94.1552 82.0758 92.8531 81.2498C92.9667 81.7471 93.0243 82.2554 93.025 82.7655C93.025 84.953 92.0094 86.828 90.5688 87.6061C90.999 87.9252 91.3177 88.3719 91.4795 88.8826C91.6413 89.3933 91.6379 89.942 91.4699 90.4507C91.3018 90.9593 90.9777 91.4021 90.5435 91.7159C90.1093 92.0297 89.5873 92.1986 89.0516 92.1986C88.5159 92.1986 87.9938 92.0297 87.5596 91.7159C87.1254 91.4021 86.8013 90.9593 86.6332 90.4507C86.4652 89.942 86.4618 89.3933 86.6236 88.8826C86.7854 88.3719 87.1041 87.9252 87.5344 87.6061C86.0937 86.8186 85.0781 84.9499 85.0781 82.7655C85.0786 82.2554 85.1363 81.747 85.25 81.2498C83.9464 82.0768 82.9463 83.3044 82.4001 84.7483C81.8538 86.1922 81.7908 87.7743 82.2206 89.257C82.6504 90.7398 83.5497 92.043 84.7834 92.9709C86.0172 93.8989 87.5187 94.4014 89.0625 94.403Z"
              fill="#E5E1D6"
            />
          </g>
          <g id="Layer 1">
            <path
              id="Vector_16"
              d="M128.884 47.5218H26.0719C22.25 47.5218 19.1531 50.5281 19.1531 54.2374V97.4499C19.1531 101.159 22.25 104.166 26.0719 104.166H46.2375V90.1218C46.2457 89.5778 46.4695 89.0593 46.8597 88.6802C47.25 88.3011 47.7747 88.0924 48.3187 88.0999H106.637C107.182 88.0924 107.707 88.301 108.098 88.68C108.488 89.0591 108.713 89.5775 108.722 90.1218V104.166H128.884C132.706 104.166 135.803 101.159 135.803 97.4499V54.2374C135.803 50.5281 132.706 47.5218 128.884 47.5218Z"
              fill="#035130"
            />
            <path
              id="Vector_17"
              opacity="0.2"
              d="M108.722 90.1251C108.713 89.5808 108.488 89.0623 108.098 88.6833C107.707 88.3043 107.182 88.0957 106.637 88.1032H48.3031C47.7591 88.0957 47.2344 88.3044 46.8441 88.6835C46.4539 89.0626 46.2301 89.5811 46.2219 90.1251V94.2064C46.2301 93.6624 46.4539 93.1439 46.8441 92.7648C47.2344 92.3857 47.7591 92.177 48.3031 92.1845H106.637C107.182 92.177 107.707 92.3856 108.098 92.7646C108.488 93.1436 108.713 93.6621 108.722 94.2064V90.1251Z"
              fill="#024426"
            />
            <path
              id="Vector_18"
              d="M19.1375 93.3687V97.4499C19.1375 101.159 22.2344 104.166 26.0562 104.166H46.2218V100.084H26.0562C22.2344 100.084 19.1375 97.0781 19.1375 93.3687Z"
              fill="#024426"
            />
            <path
              id="Vector_19"
              d="M128.884 100.084H108.722V104.166H128.884C132.706 104.166 135.803 101.159 135.803 97.4499V93.3687C135.803 97.0781 132.706 100.084 128.884 100.084Z"
              fill="#024426"
            />
            <path
              id="Vector_20"
              d="M23.3031 71.7626H131.637C132.742 71.7626 133.802 71.3237 134.583 70.5425C135.364 69.7613 135.803 68.7018 135.803 67.597V55.4876C135.803 51.7782 132.706 48.772 128.884 48.772H26.0719C22.25 48.772 19.1531 51.7782 19.1531 55.4876V67.597C19.1531 68.6991 19.5898 69.7562 20.3677 70.537C21.1455 71.3178 22.201 71.7585 23.3031 71.7626V71.7626Z"
              fill="#012B18"
            />
            <path
              id="Vector_21"
              d="M23.3031 70.5124H131.637C132.185 70.5124 132.726 70.4047 133.232 70.1954C133.737 69.986 134.196 69.6792 134.583 69.2924C134.97 68.9055 135.277 68.4463 135.486 67.9409C135.695 67.4355 135.803 66.8939 135.803 66.3468V54.2374C135.803 50.5281 132.706 47.5218 128.884 47.5218H26.0719C22.25 47.5218 19.1531 50.5281 19.1531 54.2374V66.3468C19.1531 67.4489 19.5898 68.5061 20.3677 69.2869C21.1455 70.0676 22.201 70.5083 23.3031 70.5124V70.5124Z"
              fill="#08663E"
            />
            <path
              id="Vector_22"
              d="M128.884 47.5218H26.0719C22.25 47.5218 19.1531 50.5281 19.1531 54.2374V58.7249C19.1531 55.0124 22.25 52.0062 26.0719 52.0062H128.884C132.706 52.0062 135.803 55.0124 135.803 58.7249V54.2374C135.803 50.5281 132.706 47.5218 128.884 47.5218Z"
              fill="#177246"
            />
            <path
              id="Vector_23"
              d="M123.931 60.4564H105.522C103.837 60.4564 102.472 61.8212 102.472 63.5048C102.472 65.1884 103.837 66.5533 105.522 66.5533H123.931C125.616 66.5533 126.981 65.1884 126.981 63.5048C126.981 61.8212 125.616 60.4564 123.931 60.4564Z"
              fill="#00331E"
            />
            <path
              id="Vector_24"
              d="M32.6718 63.5048C32.6718 61.8212 31.307 60.4564 29.6234 60.4564C27.9398 60.4564 26.575 61.8212 26.575 63.5048C26.575 65.1884 27.9398 66.5533 29.6234 66.5533C31.307 66.5533 32.6718 65.1884 32.6718 63.5048Z"
              fill="#00331E"
            />
            <path
              id="Vector_25"
              d="M29.625 61.6498H29.6218C28.5967 61.6498 27.7656 62.4809 27.7656 63.506V63.5092C27.7656 64.5343 28.5967 65.3654 29.6218 65.3654H29.625C30.6501 65.3654 31.4812 64.5343 31.4812 63.5092V63.506C31.4812 62.4809 30.6501 61.6498 29.625 61.6498Z"
              fill="#B3A369"
            />
            <path
              id="Vector_26"
              d="M41.4 63.5048C41.4 61.8212 40.0351 60.4564 38.3515 60.4564C36.6679 60.4564 35.3031 61.8212 35.3031 63.5048C35.3031 65.1884 36.6679 66.5533 38.3515 66.5533C40.0351 66.5533 41.4 65.1884 41.4 63.5048Z"
              fill="#00331E"
            />
          </g>
        </g>
      </g>
    </svg>

    <h4 class="text-center">Generating pdf</h4>
    <h4 class="text-center">Please Wait ...</h4>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#tempPaper {
  animation: papper infinite ease-in-out alternate 1s;
  transform-origin: bottom;
  animation-delay: none;
}
#Shade {
  animation: shade infinite ease-in-out alternate 2s;
  transform-origin: bottom;
}

@keyframes papper {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(0, 10%);
  }
  75% {
    transform: translate(0, 20%);
  }
  100% {
    transform: translate(0, 30%);
  }
}
</style>