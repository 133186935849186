<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogDownloadReport"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      scrollable
      max-width="400px"
    >
      <v-card class="rounded-max">
        <v-toolbar dense flat>
          <h3>Select a report to download</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2" v-if="dataDownload">
          <v-container>
            <div class="d-flex justify-center">
              <a
              v-if="role !== 'SCHOLAR'"
                class="no_decoration"
                :href="dataDownload.path_assessment"
                target="_blank"
              >
                <v-card
                  width="150px"
                  height="150px"
                  class="
                    rounded-max
                    d-flex
                    align-center
                    justify-center
                    flex-column
                    mr-3
                  "
                >
                  <img
                    src="../../../assets/img/IconAssessment.png"
                    height="100px"
                    width="100px"
                    alt=""
                  />
                  Assessment</v-card
                >
              </a>
              <a
                class="no_decoration"
                :href="dataDownload.path_scholar"
                target="_blank"
              >
                <v-card
                  width="150px"
                  height="150px"
                  class="
                    rounded-max
                    d-flex
                    align-center
                    justify-center
                    flex-column
                  "
                >
                  <img
                    src="../../../assets/img/IconScholar.png"
                    height="100px"
                    width="100px"
                    alt=""
                  />
                  Scholar</v-card
                >
              </a>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "dialogDwonloadReport",

  props: ["dialogDownloadReport", "dataDownload"],
  computed: {
    ...mapState({
      role : state => state.role,
    })
  },
  methods: {},
};
</script>
<style scoped>
.no_decoration {
  text-decoration: none;
}
</style>
