<template>
  <div class="d-flex justify-center">
    <div class="overflo_temp" v-if="role == 'SCHOLAR'">
      <div class="tooltip_cust mb-2" v-if="show">
        <div
          class="d-flex justify-end"
          style="
            position: absolute;
            width: 100%;
            margin-top: -30px;
            margin-right: -10px;
          "
        >
          <v-btn x-small class="ml-3" fab @click="overflowed"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </div>
        <p class="pa-0 text-right">
          If you have any questions <br />
          regarding the report, <br />
          please Message Tanoto Foundation <br />
          on WhatsApp
        </p>
      </div>
      <div class="d-flex justify-end">
        <!-- <div>
          {{ on }}
          {{ off }}
        </div> -->
        <a
          href="https://wa.me/message/F25473QYDAPVM1"
          target="_blank"
          class="wa_me"
          style="text-decoration: none"
        >
          <v-btn fab color="#006341" depressed dark
            ><v-icon size="35">mdi-whatsapp</v-icon></v-btn
          >
        </a>
      </div>
    </div>
    <div>
      <v-col cols="3" class="ma-0 pa-0">
        <v-select
          :items="items"
          item-text="attr"
          item-value="value"
          v-model="isFullPage"
          @change="changeView"
          label="Page"
          solo
          hide-details
        ></v-select>
      </v-col>
      <div class="my-3">
        <!-- HEADER -->
        <!-- PAGE 1 -->
        <div v-if="page == 1 || isFullPage" class="d-flex">
          <div class="temp_">
            <section class="secondary-color py-3 mb-5">
              <div class="primary-color d-flex">
                <div class="avatar d-flex align-center justify-center">
                  <img
                    :src="
                      require(`../../../assets/img/ava-${$route.params.periode}-${gender}.png`)
                    "
                    height="170px"
                    alt=""
                  />
                </div>
                <div class="gradient pa-3">
                  <div>
                    <h1>
                      <span class="gold_"><u>LAPORAN</u></span
                      ><span>&nbsp;</span>
                      <span class="white--text"><u>PENGEMBANGAN</u></span>
                    </h1>
                    <h4 class="gold_ mt-5 lh">{{ person.nama_lengkap }}</h4>
                    <h4 class="white--text lh">
                      {{ person.universitas ? person.universitas : "-" }}
                    </h4>
                    <h4 class="white--text lh">
                      {{
                        person.tgl_lahir
                          ? $date(person.tgl_lahir).format("DD MMMM YYYY")
                          : "Tanggal Lahir Kosong"
                      }}
                      / {{ person.jenis_kelamin }}
                    </h4>
                    <div v-if="$route.params.periode == 'baseline'">
                      <h4 class="white--text lh mt-3" v-if="resultIq">
                        IQ : {{ resultIq.iq }}
                      </h4>
                      <h4 class="white--text lh" v-if="resultIq">
                        <i>Deskripsi: {{ resultIq.deskripsi }}</i>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- PERSONALITY -->
            <div
              class="d-flex bg-primary"
              v-if="$route.params.periode == 'baseline'"
            >
              <div class="personality-pic">
                <div class="secondary-color pt-3">
                  <div class="bg-linear-head pa-2">
                    <h4 class="text-center white--text">
                      <span class="gold_">GAMBARAN</span> KEPRIBADIAN
                    </h4>
                  </div>
                </div>
                <div class="d-flex flex-column align-center pa-3">
                  <img
                    :src="dataDisc.img"
                    height="200px"
                    class="mb-3"
                    alt="tipe"
                  />
                  <p class="ma-0 grey--text">Tipe</p>
                  <h4 class="primary_">
                    <i>{{ dataDisc.aspec }}</i>
                  </h4>
                </div>
              </div>
              <div class="personality-desc d-flex">
                <div class="border_ ml-1"></div>
                <div class="pa-3">
                  <section
                    v-for="(item, idx) in dataDisc.personality"
                    :key="idx"
                  >
                    <p class="ma-0 text-center grey--text">{{ item.name }}</p>
                    <p class="text-center text-p">{{ item.desc }}</p>
                  </section>
                </div>
              </div>
            </div>
            <div class="secondary-color pt-3 mt-3 mb-1">
              <div class="primary-color px-3">
                <h1 class="white--text">
                  <i
                    >PSYCHOLOGICAL RESILIENCE
                    <span class="gold_">DESCRIPTION</span></i
                  >
                </h1>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <section class="resilence-txt ml-3">
                <p class="ma-0 test-p text-justify" v-html="resilience"></p>
              </section>
            </div>
          </div>
        </div>

        <!-- PAGE 2 -->
        <div class="d-flex" v-if="page == 2 || isFullPage">
          <!-- RESILENCE -->

          <div class="temp_">
            <!-- VALUES & COMPETENCIES -->
            <header class="d-flex justify-end mb-3 mt-5">
              <b
                ><u>Nama : {{ person.nama_lengkap }}</u></b
              >
            </header>
            <div class="secondary-color pt-3 mt-3 mb-1">
              <div class="bg-linear-head px-3">
                <h1 class="white--text text-center">
                  <i>VALUES & COMPETENCIES</i>
                </h1>
              </div>
            </div>
            <div class="d-flex bg-primary">
              <div class="half__">
                <h3 class="primary_ text-center">
                  <u><i>RECAP VALUES & COMPETENCIES</i></u>
                </h3>
                <div class="my-5">
                  <div id="donut"></div>
                </div>
                <div class="gold-line mb-3"></div>
                <!-- COSTUME BAR -->
                <div class="bar__">
                  <div id="bar">
                    <section
                      class="d-flex mb-1"
                      v-for="(item, idx) in dataScore"
                      :key="idx"
                    >
                      <div class="lable__ text-p px-1 text-capitalize">
                        <i>{{ item.name.replace(/_/g, " ") }}</i>
                      </div>
                      <div class="val_bar">
                        <div
                          class="standar d-flex align-center"
                          :style="`width: ${item.standar * 50}px`"
                        >
                          <div
                            class="score"
                            :style="`width: ${item.score * 50}px`"
                          ></div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- garis garis untuk tab -->
                  <div class="d-flex">
                    <section class="lable__"></section>
                    <section class="val_bar">
                      <div class="d-flex">
                        <section v-for="i in 6" :key="i">
                          <div v-if="i <= 4">
                            <div
                              class="param-bar"
                              :style="`height:${heightOfBar}px; margin-top:-${heightOfBar}px`"
                            ></div>
                            {{ i - 1 }}
                          </div>
                          <div v-if="i == 5">
                            <div
                              class="param-ke-5"
                              :style="`height:${heightOfBar}px; margin-top:-${heightOfBar}px`"
                            ></div>
                            {{ i - 1 }}
                          </div>
                          <div v-if="i == 6">
                            {{ i - 1 }}
                          </div>
                        </section>
                      </div>
                      <div class="d-flex mt-3">
                        <section class="d-flex align-center mr-3">
                          <div class="smoll-square secondary-color mr-1"></div>
                          <p class="ma-0 text-p">Skor Saat ini</p>
                        </section>
                        <section class="d-flex align-center">
                          <div class="smoll-square standar-color mr-1"></div>
                          <p class="ma-0 text-p">Standar Skor</p>
                        </section>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="primary-color">
                  <h3 class="text-center gold_">
                    <u><i>VALUES DESCRIPTION</i></u>
                  </h3>
                </div>
                <section class="d-flex">
                  <!-- <div class="border-green"></div> -->
                  <div class="mt-3">
                    <section
                      class="pa-1"
                      v-for="(item, idx) in values"
                      :key="idx"
                    >
                      <p class="ma-0 text-capitalize">
                        <b
                          ><i>{{ item.name.replace(/_/g, " ") }}</i></b
                        >
                      </p>
                      <div class="d-flex mt-1">
                        <div class="temp-badge">
                          <img :src="item.img" height="50px" alt="" />
                        </div>
                        <div class="px-2 box-size">
                          <p class="text-p ma-0">
                            {{ item.text ? item.text : "No Description" }}
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>
                </section>
                <!-- <div class="pl-5">
                  <section
                    class="pa-1"
                    v-for="(item, idx) in competenciesLeft"
                    :key="idx"
                  >
                    <p class="ma-0 text-capitalize">
                      <b
                        ><i>{{ item.name.replace(/_/g, " ") }}</i></b
                      >
                    </p>
                    <div class="d-flex mt-1">
                      <div class="temp-badge">
                        <img :src="item.img" height="50px" alt="" />
                      </div>
                      <div class="px-2 box-size">
                        <p class="text-p ma-0">
                          {{ item.text ? item.text : "No Description" }}
                        </p>
                      </div>
                    </div>
                  </section>
                </div> -->
              </div>
              <div class="half__ d-flex">
                <!-- <div class="primary-color">
                  <h3 class="text-center gold_">
                    <u><i>VALUES DESCRIPTION</i></u>
                  </h3>
                </div>
                <section class="d-flex">
                  <div class="border-green"></div>
                  <div class="mt-3">
                    <section
                      class="pa-1"
                      v-for="(item, idx) in values"
                      :key="idx"
                    >
                      <p class="ma-0 text-capitalize">
                        <b
                          ><i>{{ item.name.replace(/_/g, " ") }}</i></b
                        >
                      </p>
                      <div class="d-flex mt-1">
                        <div class="temp-badge">
                          <img :src="item.img" height="50px" alt="" />
                        </div>
                        <div class="px-2 box-size">
                          <p class="text-p ma-0">
                            {{ item.text ? item.text : "No Description" }}
                          </p>
                        </div>
                      </div>
                    </section>
                  </div>
                </section> -->
                <div class="border-green"></div>
                <div class="">
                  <div class="primary-color">
                    <h3 class="text-center gold_">
                      <u><i>COMPETENCIES DESCRIPTION</i></u>
                    </h3>
                  </div>
                  <section>
                    <section
                      class="pa-1"
                      v-for="(item, idx) in competenciesFull"
                      :key="idx"
                    >
                      <p class="ma-0 text-capitalize">
                        <b
                          ><i>{{ item.name.replace(/_/g, " ") }}</i></b
                        >
                      </p>
                      <div class="d-flex mt-1">
                        <div class="temp-badge">
                          <img :src="item.img" height="50px" alt="" />
                        </div>
                        <div class="px-2 box-size">
                          <p class="text-p ma-0">
                            {{ item.text ? item.text : "No Description" }}
                          </p>
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <!-- VALUES & COMPETENCIES -->
        </div>
        <!-- PAGE 3 -->
        <section v-if="page == 3 || isFullPage" class="d-flex">
          <div class="temp_" v-if="dataSaran.length">
            <header class="d-flex justify-end mb-3 mt-5">
              <b
                ><u>Nama : {{ person.nama_lengkap }}</u></b
              >
            </header>
            <div class="secondary-color pt-3 mt-3 mb-1">
              <div class="bg-linear-head px-3">
                <h1 class="white--text text-center">
                  <i>SARAN PENGEMBANGAN</i>
                </h1>
              </div>
            </div>
            <table style="border-collapse: collapse">
              <tr class="text-center primary_">
                <td style="width: 20%" class="pa-5">
                  <b><i>Values & Competencies</i></b>
                </td>
                <td style="width: 15%">&nbsp;</td>
                <td style="width: 30%">
                  <b><i>Saran Umum</i></b>
                </td>
                <td style="width: 30%">
                  <b><i>Program Tanoto Foundation</i></b>
                </td>
              </tr>
              <template v-for="item in dataSaran">
                <tr :key="`${item.aspek}1`">
                  <td rowspan="2">
                    <div class="d-flex justify-center">
                      <img :src="item.img" height="150px" alt="" />
                    </div>
                  </td>
                  <td class="pa-3 primary_">
                    <b><i>Self Learning</i></b>
                  </td>
                  <td>
                    <div
                      class="my-3"
                      style="height: 100%; display: inline-table"
                    >
                      <ul>
                        <li
                          v-for="(saran, idx) in item.self_learning_saran_umum"
                          :key="idx"
                        >
                          {{ saran }}
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="fix_height pa-2">
                    <div style="height: 100%; display: inline-table">
                      <ul>
                        <li
                          v-for="(
                            saran, idx
                          ) in item.self_learning_program_tanoto"
                          :key="idx"
                        >
                          {{ saran }}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr :key="`${item.aspek}2`">
                  <td class="pa-3 primary_">
                    <b><i>Facilitated Learning</i></b>
                  </td>
                  <td class="fix_height pa-2">
                    <div style="height: 100%; display: inline-table">
                      <div
                        class="my-2"
                        v-if="item.facilitated_group_saran_umum.length"
                      >
                        <b>Group Activity :</b>
                        <ul>
                          <li
                            v-for="(
                              saran, idx
                            ) in item.facilitated_group_saran_umum"
                            :key="idx"
                          >
                            {{ saran }}
                          </li>
                        </ul>
                      </div>
                      <template v-if="item.facilitated_coaching_saran_umum">
                        <div
                          class="my-1"
                          v-if="item.facilitated_coaching_saran_umum.length"
                        >
                          <b>Coaching Activity :</b>
                          <ul>
                            <li
                              v-for="(
                                saran, idx
                              ) in item.facilitated_coaching_saran_umum"
                              :key="idx"
                            >
                              {{ saran }}
                            </li>
                          </ul>
                        </div>
                      </template>
                      <div
                        class="my-2"
                        v-if="item.facilitated_classroom_saran_umum.length"
                      >
                        <b>Classroom Training :</b>
                        <ul>
                          <li
                            v-for="(
                              saran, idx
                            ) in item.facilitated_classroom_saran_umum"
                            :key="idx"
                          >
                            {{ saran }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td class="pa-2 fix_height">
                    <div style="height: 100%; display: inline-table">
                      <div
                        class="my-2"
                        v-if="item.facilitated_group_program_tanoto.length"
                      >
                        <b>Group Activity :</b>
                        <ul>
                          <li
                            v-for="(
                              saran, idx
                            ) in item.facilitated_group_program_tanoto"
                            :key="idx"
                          >
                            {{ saran }}
                          </li>
                        </ul>
                      </div>
                      <template v-if="item.facilitated_coaching_program_tanoto">
                        <div
                          class="my-1"
                          v-if="item.facilitated_coaching_program_tanoto.length"
                        >
                          <b>Coaching Activity :</b>
                          <ul>
                            <li
                              v-for="(
                                saran, idx
                              ) in item.facilitated_coaching_saran_umum"
                              :key="idx"
                            >
                              {{ saran }}
                            </li>
                          </ul>
                        </div>
                      </template>
                      <div
                        class="my-2"
                        v-if="item.facilitated_classroom_program_tanoto.length"
                      >
                        <b>Classroom Training :</b>
                        <ul>
                          <li
                            v-for="(
                              saran, idx
                            ) in item.facilitated_classroom_program_tanoto"
                            :key="idx"
                          >
                            {{ saran }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </section>
      </div>
      <section class="d-flex justify-center my-3" v-if="!isFullPage">
        <v-btn
          depressed
          class="mr-2 custom-paginate"
          :dark="page == 1 ? true : false"
          :color="page == 1 ? `green` : `white`"
          @click="switchPage(1)"
          >1</v-btn
        >
        <v-btn
          depressed
          class="mr-2 custom-paginate"
          :dark="page == 2 ? true : false"
          :color="page == 2 ? `green` : `white`"
          @click="switchPage(2)"
          >2</v-btn
        >
        <v-btn
          v-if="dataSaran.length"
          depressed
          class="mr-2 custom-paginate"
          :dark="page == 3 ? true : false"
          :color="page == 3 ? `green` : `white`"
          @click="switchPage(3)"
          >3</v-btn
        >
      </section>
    </div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
// import CommentSectionTop from "../Comment/commentSectionTop.vue";
// import CommentSectionCenter from "../Comment/commentSectionCenter.vue";
// import CommentSectionBottom from "../Comment/commentSectionBottom.vue";
import { mapState } from "vuex";
export default {
  name: "baselineScholar",
  // components: { CommentSectionTop, CommentSectionCenter, CommentSectionBottom },
  props: [
    "values",
    "competenciesRight",
    "competenciesLeft",
    "person",
    "dataScore",
    "resultIq",
    "dataChart",
    "dataDisc",
    "dataSaran",
    "resilience",
    "competenciesFull",
  ],
  data() {
    return {
      heightOfBar: 0,
      page: 1,
      show: true,
      on: 0,
      off: 0,
      items: [
        { attr: "Multiple Page", value: false },
        { attr: "Full Page", value: true },
      ],
      isFullPage: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
    gender() {
      let data = null;
      if (this.person) {
        let gender = this.person.jenis_kelamin;
        if (gender == "Laki-laki") {
          data = "l";
        } else if (gender == "Perempuan") {
          data = "p";
        } else {
          data = "gay";
        }
      }
      return data;
    },
  },
  mounted() {
    setTimeout(() => this.getHeight(), 500);
    setTimeout(() => this.donut(), 500);
    this.showOverflowed();
  },
  methods: {
    overflowed() {
      this.show = false;
      this.on = 0;
      this.showOverflowed();
    },
    showOverflowed() {
      setInterval(() => {
        if (this.show) {
          this.on++;
          if (this.on == 10) {
            this.show = false;
            this.on = 0;
          }
        } else if (!this.show) {
          this.off++;
          if (this.off == 30) {
            this.show = true;
            this.off = 0;
          }
        }
      }, 1000);
    },
    changeView() {
      setTimeout(() => this.getHeight(), 500);
      setTimeout(() => this.donut(), 500);
    },
    switchPage(page) {
      this.page = page;
      window.scrollTo(0, 0);
      if (page == 2) {
        setTimeout(() => this.getHeight(), 500);
        setTimeout(() => this.donut(), 500);
      }
    },
    getHeight() {
      this.heightOfBar = document.getElementById("bar").offsetHeight;
    },
    donut() {
      var options = {
        series: this.dataChart,
        colors: ["#006341", "#b3a369", "#607D8B"],
        labels: [
          "Di atas Standar Skor",
          "Mencapai Standar Skor",
          "Di bawah Standar skor",
        ],
        dataLabels: {
          // eslint-disable-next-line no-unused-vars
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex];
          },
        },
        chart: {
          type: "pie",
          height: 200,
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 100,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            donut: {
              size: "48%",
            },
          },
        },
      };

      var chart = new ApexCharts(document.querySelector("#donut"), options);
      chart.render();
    },
  },
};
</script>

<style scoped>
* {
  scroll-behavior: smooth;
}
.primary-color {
  background: #006341;
}
.secondary-color {
  background: #b3a369 !important;
}
.gradient {
  background: linear-gradient(
    14deg,
    rgba(0, 99, 65, 1) 26%,
    rgba(0, 0, 0, 1) 99%
  );
  width: 650px;
}
.avatar {
  width: 200px;
}
.primary_ {
  color: #006341;
}
.gold_ {
  color: #ffe599;
}
.border_ {
  padding: 1px;
  background: #b3a369;
}
.lh {
  line-height: normal;
}
.temp_ {
  width: 900px;
  background: white;
  padding: 40px;
  box-sizing: border-box;
}
.half__ {
  width: calc(850px / 2);
}
.bg-primary {
  background: #faf9f4;
}
.personality-pic {
  width: 350px;
}
.personality-desc {
  width: 500px;
}
.bg-linear-head {
  background: linear-gradient(
    0deg,
    rgba(0, 99, 65, 1) 42%,
    rgba(0, 0, 0, 0.7539215515307686) 100%
  );
}
.text-p {
  font-size: small;
}
.resilence-txt {
  padding: 25px 15px;
  border-radius: 25px;
  border: #b3a369 2px solid;
}
.gold-line {
  width: 100%;
  padding: 1px;
  background: gold;
}
.border-green {
  padding: 2px;
  background: #006341;
  height: auto;
}
.lable__ {
  width: 150px;
}
.val_bar {
  /* background: violet; */
  width: 250px;
}
.standar {
  position: relative;
  background: #d7ceaf;
  height: 30px;
}
.standar-color {
  background: #d7ceaf;
}
.score {
  position: absolute;
  background: #b3a369;
  height: 20px;
}
.smoll-square {
  width: 10px;
  height: 10px;
}
.param-bar {
  width: calc(250px / 5) !important;
  border-bottom: 1px #e0e0e0 solid;
  border-left: 1px #e0e0e0 solid;
}
.param-ke-5 {
  width: calc(250px / 5) !important;
  border-bottom: 1px #e0e0e0 solid;
  border-left: 1px #e0e0e0 solid;
  border-right: 1px #e0e0e0 solid;
}
.temp-badge {
  margin-right: 20px;
}
.box-size {
  box-sizing: border-box;
}
.fix_height {
  height: 5px;
}
#bar {
  position: relative;
  z-index: 2 !important;
  overflow: hidden;
  box-sizing: border-box;
}
td {
  border: #b3a369 3px solid;
  font-size: small;
}
.custom-paginate {
  min-width: 0 !important;
}
.tooltip_cust {
  position: relative;
  background: #006341;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  display: block;
}
.overflo_temp {
  position: fixed;
  right: 10px;
  bottom: 20px;
}
/* .wa_me {
  width: 60px;
  height: 60px;
  background: #006341;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
} */
</style>
