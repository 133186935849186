<template>
  <div>
    <div class="ma-3 bg-graph" v-if="graph">
      <v-stage ref="stage" :config="stageSize">
        <v-layer>
          <v-image
            :config="{
              image: image,
            }"
          />
          <v-line
            v-if="graph.most_d"
            :config="{
              points: [
                most[`d${graph.most_d}`].x,
                most[`d${graph.most_d}`].y,
                most[`i${graph.most_i}`].x,
                most[`i${graph.most_i}`].y,
                most[`s${graph.most_s}`].x,
                most[`s${graph.most_s}`].y,
                most[`c${graph.most_c}`].x,
                most[`c${graph.most_c}`].y,
              ],
              tension: 0,
              closed: false,
              stroke: 'red',
            }"
          />
          <!--  109, 385 -->
          <v-line
            v-if="graph.least_d"
            :config="{
              x: 169,
              points: [
                least[`d${graph.least_d}`].x,
                least[`d${graph.least_d}`].y,
                least[`i${graph.least_i}`].x,
                least[`i${graph.least_i}`].y,
                least[`s${graph.least_s}`].x,
                least[`s${graph.least_s}`].y,
                least[`c${graph.least_c}`].x,
                least[`c${graph.least_c}`].y,
              ],
              tension: 0,
              closed: false,
              stroke: 'red',
            }"
          />

          <v-line
            :config="{
              x: 340,
              points: [
                change[`d${graph.change_d}`].x,
                change[`d${graph.change_d}`].y,
                change[`i${graph.change_i}`].x,
                change[`i${graph.change_i}`].y,
                change[`s${graph.change_s}`].x,
                change[`s${graph.change_s}`].y,
                change[`c${graph.change_c}`].x,
                change[`c${graph.change_c}`].y,
              ],
              tension: 0,
              closed: false,
              stroke: 'red',
            }"
          />
        </v-layer>
      </v-stage>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "graphDisc",
  props: ["graph"],
  computed: {
    ...mapState({
      most: (state) => state.point.most,
      least: (state) => state.point.least,
      change: (state) => state.point.change,
    }),
  },
  data() {
    return {
      image: null,
      stageSize: {
        width: 480,
        height: 430,
      },
    };
  },
  created() {
    const image = new window.Image();
    image.src = require("../../../assets/img/graph-disc.png");
    image.onload = () => {
      // set image only when it is loaded
      this.image = image;
    };
  },
};
</script>

<style scoped>
/* .bg-graph {
    background: url('../../../assets/img/graph-disc.png');
    height: 500px;
    width: 700px;
} */
</style>
